import React, { useState, useEffect } from 'react';

import Tiles from '../../components/tiles/Tiles'
import Layout from "../../components/layout"


const Index = () => {


    return (
        <Layout>
            <Tiles />
        </Layout>
    )
}

export default Index
